import React from "react";
import "./PlayCards.scss";
import img1 from "../../assets/b2yfa4.jpg";
import img2 from "../../assets/1p7rco.jpg";
import img3 from "../../assets/zmqy59.jpg";

const PlayCards = () => {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">Where to play Gates Of Olympus</h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">1WIN Casino</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19">PLAY</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">IZZI Casino</p>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19"> PLAY </button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">PinUP Casino</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19">PLAY</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        Every gambler has heard about the popular Gates Of Olympus video slot at
        least once . This is a slot machine that has won the hearts of many
        gamblers thanks to its interesting mechanics and generous payouts. Many
        online casinos offer to play this slot, but it is not always easy to
        determine where to play the most profitable. When choosing a casino, you
        should take into account the availability of bonuses, reviews from other
        players and the honesty of the random number generator (RNG).
      </p>
    </div>
  );
};
export default PlayCards;
