import React from "react";
import img1 from "../../../assets/b2yfa4.jpg";
import img2 from "../../../assets/1p7rco.jpg";
import img3 from "../../../assets/zmqy59.jpg";

const PlayCards = () => {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">Where to download Gates Of Olympus?</h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">1WIN Casino</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">IZZI Casino</p>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19 btn-green"> PLAY </button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">PinUP Casino</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        In the era of modern technology, downloading games and applications to
        mobile devices has become incredibly simple and convenient. This is
        especially true for such popular platforms as Android and iOS. But how
        to download and install the famous Gates Of Olympus game? Let's figure
        it out!
      </p>
    </div>
  );
};
export default PlayCards;
