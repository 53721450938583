import React, { useRef } from "react";
import img from "../../../assets/download1.jpg";
import img1 from "../../../assets/download2.jpg";

const Commodity = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="commodity">
      <div className="commodity-container">
        <div className="commodity-guidebook">
          <p>Content:</p>
          <ul className="tableNavi">
            <li onClick={() => handleClick(ref1)}>Download on Android</li>
            <li onClick={() => handleClick(ref2)}>Download on iOS</li>
            <li onClick={() => handleClick(ref3)}>
              Where is it better to download Gates Of Olympus
            </li>
            <li onClick={() => handleClick(ref4)}>
              How to install Gates Of Olympus on your phone
            </li>
            <li onClick={() => handleClick(ref5)}>
              How to install Gates Of Olympus on iPhone
            </li>
            <li onClick={() => handleClick(ref6)}>
              How to install Gates Of Olympus on Windows
            </li>
            <li onClick={() => handleClick(ref7)}>
              How best to play Gates of Olympus – through an application on a
              smartphone or desktop computer
            </li>
            <li onClick={() => handleClick(ref8)}>
              FAQ: when downloading the game
            </li>
            <li onClick={() => handleClick(ref9)}>
              Is it convenient to play from the phone?
            </li>
          </ul>
        </div>
        <div className="pragmatic-play" ref={ref1}>
          <h2>Download on Android </h2>
          <p>
            Play Market: This is the main application for downloading all
            content on Android. Just enter the name of the game in the search
            bar, find the app you need and click install. The download will
            start automatically, and you will only have to wait for the
            installation.
          </p>
          <p>
            APK Download: If for some reason you cannot download the game from
            the Play Market, then there is always an option to download the apk
            file. But be careful when downloading files from unknown sources –
            it may not be safe.
          </p>
          <div className="pragmatic-play-img">
            <img src={img} alt="img" />
            <p>Play Gates Of Olympus from your mobile phone </p>
            <a href="https://1wiaxy.xyz/#79x5" target="_blank">
              <button className="button-19 btn-60">
                Download Gates Of Olympus Demo
              </button>
            </a>
          </div>
          <div>
            <p>
              Desktop and Android: If you already have the game on your
              computer, you can transfer the apk file to your smartphone and
              install it. However, it is recommended to download games directly
              from your mobile for data security.
            </p>
            <p>
              Size and Registration: Pay attention to the size of the game to
              make sure that there is enough memory on your device. Some games
              may also require registration to access the full version.
            </p>
          </div>
        </div>

        <div className="gates-of-olympus" ref={ref2}>
          <h2>Download on iOS</h2>
          <p>
            AppStore: For all iPhone and other Apple devices users, the AppStore
            is the main source of apps and games. Just find Gates Of Olympus in
            the search and click the download button.
          </p>
          <p>
            Compatibility and iOS: Make sure your iPhone or iPad is compatible
            with the version of the game. Some older devices may not support the
            latest updates.
          </p>
          <p>
            Download via iTunes: If you have a desktop with iTunes, you can
            first download the game there, and then sync it with your iPhone.
          </p>
          <p>
            Free or paid?: Some games may be paid. Before downloading, make sure
            that you are ready to pay or that you have the required amount of
            bonus funds.
          </p>
          <h2 ref={ref3}>Where is it better to download Gates Of Olympus </h2>
          <p>
            Official Stores: It's always best to download games and apps from
            official stores such as Play Market or AppStore. This guarantees the
            security and quality of the content.
          </p>
          <p>
            Reviews and recommendations: Before downloading the game, it is
            recommended to read reviews and opinions of other users. This can
            help determine the choice.
          </p>
          <p>
            Official Developer Website: Some developers provide direct links to
            download their games. It's also a safe way to get the right app.
          </p>
          <p>
            Beware of pirated versions: In addition to the risk of infection
            with viruses, pirated versions of games often do not work correctly
            and can cause problems with your device.
          </p>
          <div className="pragmatic-play-img">
            <a href="https://1wiaxy.xyz/#79x5" target="_blank">
              <button className="button-19 btn-60">
                Download Gates Of Olympus Demo
              </button>
            </a>
          </div>
          <h3 ref={ref4}> How to install Gates Of Olympus on your phone </h3>
          <p>
            Downloading and installing games on a smartphone has become
            incredibly convenient in modern times. If you are a fan of Gates Of
            Olympus or, as it is also called, Gates of Olympus, you should
            follow the following instructions:
          </p>
          <p>
            On Android: Open the Play Market app on your mobile device, enter
            the name of the game in the search bar, select the appropriate
            application from the list and click "Install".
          </p>
          <p>
            On iOS (iPhone, iPad): For Apple devices, visit the App Store, find
            the game in the search and click on the download button.
          </p>
          <div className="pragmatic-play-img">
            <a href="https://1wiaxy.xyz/#79x5" target="_blank">
              <button className="button-19 btn-60">
                Download Gates Of Olympus Demo
              </button>
            </a>
          </div>
        </div>
        <div className="gates-of-olympus" ref={ref5}>
          <h3>How to install Gates Of Olympus on iPhone</h3>
          <p>
            AppStore: This is the official app store from Apple. Launch the
            AppStore on your iPhone, enter Gates Of Olympus in the search bar,
            find the app you need and click on the download button
          </p>
          <p>
            iOS Compatibility: Before downloading, make sure that your iPhone is
            compatible with the version of the game.
          </p>
          <p>
            Download via iTunes: If you have iTunes installed on your computer,
            you can download the game there and then sync it with your iPhone.
          </p>
          <p>
            Free or paid?: Check carefully whether the game is paid or available
            for free download.
          </p>
          <h3 ref={ref6}>How to install Gates Of Olympus on Windows</h3>
          <p>To install the game on Windows:</p>
          <p>
            Visit the official website of the developer or another reliable
            source where the PC version of the game is presented.
          </p>
          <p>Download the installation file of the game for Windows.</p>
          <p>Run the file and follow the instructions of the installer.</p>
          <p>
            After the installation is complete, the game will be available to
            run from the Start menu or desktop.
          </p>
          <div className="pragmatic-play-img">
            <img src={img1} alt="img" />
            <p>Gates Of Olympus free download</p>
            <a href="https://1wiaxy.xyz/#79x5" target="_blank">
              <button className="button-19 btn-60">
                Download Gates Of Olympus Demo
              </button>
            </a>
          </div>
          <h3 ref={ref7}>
            How best to play Gates of Olympus – through an application on a
            smartphone or desktop computer
          </h3>
          <p>
            Mobile application: Playing on a smartphone, you get the opportunity
            to play anytime and anywhere. It's perfect for short gaming sessions
            on the go.
          </p>
          <p>
            Desktop computer: Playing on a PC can provide higher resolution,
            improved graphics settings and more comfortable control, especially
            if the game requires precision.
          </p>
          <p>
            Gaming sessions: If you are planning long gaming sessions, playing
            on a PC can be more comfortable for the eyes and landing.
          </p>
          <p>
            Depends on preferences: Ultimately, the choice of platform depends
            on your personal preferences and what you are looking for in the
            gaming experience. Regardless of your choice, Gates Of Olympus
            promises a lot of fun.
          </p>
        </div>
        <div className="gates-of-olympus" ref={ref8}>
          <h3>FAQ: when downloading the game</h3>
          <p>Do I need to download the game to play on PC?</p>
          <p>
            Yes, to play on a PC, you need to download and install the desktop
            version of the Gates Of Olympus game. Go to the developer's official
            website or another reliable resource to download.
          </p>
          <p>What is the minimum amount to start the game?</p>
          <p>
            You can start the game for free, but certain features or levels may
            require in-game purchases. Details are given in the description of
            the game in the AppStore or Play Market.
          </p>
          <p>How is the Gates Of Olympus winnings calculated?</p>
          <p>
            The winnings in the game are calculated based on the combination of
            symbols that you receive during the game. Detailed rules and payout
            coefficients can be found in the Game Rules section inside the app.
          </p>
          <p>Who is the game developer?</p>
          <p>
            The developer of the Gates Of Olympus game is [company name]. You
            can learn more about the developer and his other projects by
            visiting their official website.
          </p>
          <p>How do I install the Gates of Olympus app on my iPhone or iPad?</p>
          <p>
            To install the application on Apple devices, open the AppStore,
            enter Gates of Olympus in the search bar, find the appropriate
            application and click the download button. After the download is
            complete, the app will automatically install on your iPhone or iPad.
          </p>
          <div className="pragmatic-play-img">
            <a href="https://1wiaxy.xyz/#79x5" target="_blank">
              <button className="button-19 btn-60">
                Download Gates Of Olympus Demo
              </button>
            </a>
          </div>
          <h3 ref={ref9}>Is it convenient to play from the phone?</h3>
          <p>
            Mobility: One of the main advantages of using a smartphone to play
            Gates Of Olympus is the ability to play anywhere and anytime.
            Whether it's a trip in transport, a lunch break or a cozy evening at
            home - your game is always with you.
          </p>
          <p>
            Touch screen interface: The developers have optimized the game
            interface for touch screens, which makes the control intuitive and
            comfortable.
          </p>
          <p>
            Updates and Notifications: When playing on a mobile device, you
            receive instant notifications about new updates, promotions and
            events, which allows you to always be up to date with the latest
            news.
          </p>
          <p>
            Optimization for phone resources: The Gates of Olympus game is
            optimized to ensure stable operation even on less powerful devices.
            But do not forget to monitor the battery charge – active gaming
            sessions can quickly deplete it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Commodity;
