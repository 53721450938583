import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-text">
          <p>thegatesolympus.com (c) {currentYear}</p>
          <p>
            Contacts:
            <a href="mailto:help@gates-of-olympusru.ru">
              onlinegamblegames@gmail.com
            </a>
          </p>
        </div>
        <div className="footer-warning">
          <span>18+</span>
          <p>
            Gambling is for entertainment, not for earnings. Play responsibly!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
