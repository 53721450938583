import React from "react";
import GameIntro from "../components/gameIntro/demo/GameIntro";
import PlayCards from "../components/playCards/demo/PlayCards";
import Commodity from "../components/commodity/demo/Commodity";
import Promo from "../components/promo/Promo";

const Demo = () => {
  return (
    <>
      <GameIntro />
      <PlayCards />
      <Commodity />
      <Promo />
    </>
  );
};

export default Demo;
