import React from "react";
import img1 from "../../../assets/b2yfa4.jpg";
import img2 from "../../../assets/1p7rco.jpg";
import img3 from "../../../assets/zmqy59.jpg";

const PlayCards = () => {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">
        TOP sites with the game Gates of Olympus
      </h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">1WIN Casino</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">IZZI Casino</p>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19 btn-green"> PLAY </button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">PinUP Casino</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        The game Gates Of Olympus or Gates of Olympus has won over many players
        with its interesting gameplay and graphics. But it is important that the
        playground is reliable and honest. The official website of an online
        casino with a license is the main sign of reliability. Pin-Up, 1 win and
        Vavada are excellent examples of such sites. After all, when choosing an
        online casino, you need to pay attention to the brand and its
        reputation.
      </p>
    </div>
  );
};
export default PlayCards;
