import React from 'react'
import oneX from "../../assets/zmqy59.jpg"

import "./Promo.scss"

const Promo = () => {
  return (
    <div className='promo'>
        <div className="promo-cont">
            <div className='promo-cont-img'>
                <img src={oneX} alt="oneX" />
            </div>
            <div className='promo-cont-text'>
                <p>Enter the promo code and take the bonus:</p>
                <p>ONLY ON 1WIN CASINO</p>
            </div>
            <div className='promo-cont-promo'>
                <p>THEOLYMP</p>
            </div>
        </div>
    </div>
  )
}

export default Promo