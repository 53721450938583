import React, { useEffect, useState } from "react";
import image from "../../../assets/gates-of-olympus-logo.jpg";

const GameIntro = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const updateDateInterval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60 * 60 * 24);

    return () => clearInterval(updateDateInterval);
  }, []);

  const formattedDate = currentDate.toLocaleDateString("de-DE");

  const localStorageKey = "randomNumber";

  const [randomNumber, setRandomNumber] = useState(() => {
    const storedNumber = localStorage.getItem(localStorageKey);
    const parsedNumber = storedNumber ? parseInt(storedNumber, 10) : null;
    return parsedNumber !== null ? parsedNumber : generateRandomNumber();
  });

  function generateRandomNumber() {
    const newRandomNumber =
      Math.floor(Math.random() * (700000 - 300000 + 1)) + 300000;
    localStorage.setItem(localStorageKey, newRandomNumber.toString());
    return newRandomNumber;
  }

  useEffect(() => {
    // Set up an interval to update the random number every 24 hours
    const intervalId = setInterval(() => {
      generateRandomNumber();
    }, 24 * 60 * 60 * 1000); // 24 hours

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="gameintro">
      <div className="gameintro-container">
        <div className="gameintro-content">
          <div>
            <div className="gameintro-content-header">
              <div className="gameintro-content-header-path">
                <a href="/">Gates Of Olympus </a>
                <p>/ Download</p>
              </div>

              <h1 className="font-extrabold">
                Gates Of Olympus game application (download)
              </h1>
              <p>
                Maximum winnings for yesterday: <span>$420,813</span>
              </p>
            </div>
            <div className="gameintro-content-button">
              <a href="https://1wiaxy.xyz/#79x5" target="_blank">
                <button className="button-19 btn-60">
                  PLAY GATS OF OLYMPUS
                </button>
              </a>

              <p>The link to the mirror is working on {formattedDate}</p>
            </div>
          </div>

          <div className="gameintro-content-image">
            <img src={image} alt="gates-of-olympus" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameIntro;
