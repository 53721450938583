import "./App.css";
import Layout from "./components/common/Layout";
import MainPage from "./pages/MainPage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WhereToPlay from "./pages/WhereToPlay";
import Demo from "./pages/Demo";
import Download from "./pages/Download";
import { useEffect, useState } from "react";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/where-to-play" element={<WhereToPlay />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/download" element={<Download />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
