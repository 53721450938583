import React from "react";
import img1 from "../../../assets/b2yfa4.jpg";
import img2 from "../../../assets/1p7rco.jpg";
import img3 from "../../../assets/zmqy59.jpg";

const PlayCards = () => {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">
        Where can I play the Gates Of Olympus DEMO?
      </h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">1WIN Casino</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">IZZI Casino</p>

          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19 btn-green"> PLAY </button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">PinUP Casino</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19 btn-green">PLAY</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        The original Gates Of Olympus slot ("Gates of Olympus") is a very
        popular online casino slot machine that hardly needs introduction.
        Released in May 2009, the device has acquired an almost legendary status
        and to this day is one of the most played slots on the market. Having
        the opportunity to take a glimpse at the list of the most productive
        slots for one casino, we noticed that Gates Of Olympus ranks 11th in
        this list. Not bad for a slot that is almost 10 years old!.
      </p>
    </div>
  );
};
export default PlayCards;
