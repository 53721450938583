import React from "react";
import GameIntro from "../components/gameIntro/download/GameIntro";
import PlayCards from "../components/playCards/download/PlayCards";
import Commodity from "../components/commodity/download/Commodity";
import Promo from "../components/promo/Promo";

const Download = () => {
  return (
    <>
      <GameIntro />
      <PlayCards />
      <Commodity />
      <Promo />
    </>
  );
};

export default Download;
